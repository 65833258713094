import React, { Fragment, useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import ValidateUrlIcon from "@material-ui/icons/Check";
import Button from "components/templatesComponents/Button";
import NodesPicker from "da-ged-web/components/NodesListing/NodesPicker";
import { FILE } from "da-ged-web/models/Node";
import ThumbService from "services/ThumbService";
import { normalized, getFilenameFromUrl, isValidUrl } from "utils/urlUtils";
import { getFileExtension, getFileNameWithoutExtension, debounce } from "utils/commonUtils";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import FileService from "gedServices/FileService";

const imageExtensions = [".gif", ".jpeg", ".jpg", ".png", ".bmp", ".webp", ".avif"];

const useStyles = makeStyles(() => ({
  fieldsetCustom: {
    border: "1px solid rgba(0,0,0, 0.28)",
    borderRadius: 4,
    " & legend": {
      fontSize: "0.875rem",
    },
  },
  image: {
    paddingTop: "16px",
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
}));

const FilePicker = (props) => {
  const { onSelectFile, file, image, formSubmitted, pageVersion } = props;
  const { title, alt, url } = file || {};

  const isFromGed = url && url.startsWith(process.env.ged_public_url);
  const { name = getFilenameFromUrl(url) } = file || {};
  const [filename, setFilename] = useState(getFileNameWithoutExtension(name));
  const [fileExtension, setFileExtension] = useState(getFileExtension(name));
  const [inputUrl, setInputUrl] = useState(isFromGed ? "" : url);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setFilename(getFileNameWithoutExtension(name));
    setFileExtension(getFileExtension(name));
  }, [name]);

  useEffect(() => {
    setInputUrl(isFromGed ? "" : url || "");
  }, [isFromGed, url]);

  useEffect(() => {
    setImageError(false);
  }, [url]);

  const classes = useStyles();

  const { displayError } = useContext(AdminMessageContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [uploaderId] = useState(`uploader-${Math.floor(Math.random() * 100000)}`);
  const [gedModalOpen, setGedModalOpen] = useState(false);

  useEffect(() => {
    setErrors({
      name: image && url && !name,
    });
  }, [url, image, name]);

  const handleClickGed = () => {
    setGedModalOpen(true);
  };

  const handleClickUpload = () => {
    document.getElementById(uploaderId).click();
  };

  const publishFile = (f) => {
    setPublishing(true);
    return FileService.publish({ file: f, pageVersion }).then((response) => {
      const { data: publishedFile } = response;
      if (image && !process.env.thumbor_disabled) {
        let imageName = f.name || name;
        imageName = normalized(getFileNameWithoutExtension(imageName)) + getFileExtension(imageName);
        return ThumbService.createThumbs({ url: publishedFile.publicUrl, name: imageName })
          .then((thumbs) => {
            setPublishing(false);
            return {
              ...publishedFile,
              thumbs,
            };
          })
          .catch(() => {
            setPublishing(false);
            return response.data;
          });
      }
      setPublishing(false);
      return response.data;
    });
  };

  const handleUploadFile = (e) => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      setLoading(true);
      FileService.createFile({ file: fileToUpload })
        .then((response) => {
          const { data: uploadedFile } = response;
          publishFile(uploadedFile)
            .then((publishedFile) => {
              onSelectFile({
                ...publishedFile,
                ...uploadedFile,
                name: normalized(getFileNameWithoutExtension(uploadedFile.name)) + getFileExtension(uploadedFile.name),
                url: publishedFile.publicUrl,
                alt,
                title,
              });
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
              displayError("Erreur lors de la publication du fichier");
            });
        })
        .catch(() => {
          setLoading(false);
          displayError("Erreur lors du téléversement du fichier");
        });
    }
  };

  const handleSelectNode = (node) => {
    if (node && node.type === FILE) {
      setSelectedFile(node);
    } else {
      setSelectedFile(null);
    }
  };

  const handleClickValidateFile = () => {
    if (selectedFile) {
      publishFile(selectedFile).then((publishedFile) => {
        onSelectFile({
          ...publishedFile,
          ...selectedFile,
          url: publishedFile.publicUrl,
          alt,
          title,
        });
        setGedModalOpen(false);
      });
    }
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    const normalizedName = normalized(value);
    const newName = `${normalizedName}${fileExtension}`;
    setFilename(normalizedName);
    onSelectFile({
      ...file,
      name: newName,
    });
    debounce(() => {
      if (image && newName && !process.env.thumbor_disabled) {
        setLoading(true);
        ThumbService.createThumbs({ url, name: newName })
          .then((thumbs) => {
            setLoading(false);
            onSelectFile({
              ...file,
              name: newName,
              thumbs,
            });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }, 750)();
  };

  const handleTitleChange = (e) => {
    onSelectFile({
      ...file,
      title: e.target.value,
    });
  };

  const handleAltChange = (e) => {
    onSelectFile({
      ...file,
      alt: e.target.value,
    });
  };

  const showError = (key) => formSubmitted && errors[key];

  const handleImageError = useCallback((e) => {
    if (e.target.src !== "/default-image.jpg") {
      e.target.onerror = null;
      e.target.src = "/default-image.jpg";
      setImageError(true);
    }
  }, []);

  const handleChangeInputFileUrl = (e) => setInputUrl(e.target.value);

  const handleClickInputFileUrl = useCallback(() => {
    if (!process.env.thumbor_disabled) {
      const newName = getFilenameFromUrl(inputUrl);
      setLoading(true);
      ThumbService.createThumbs({ url: inputUrl, name: newName })
        .then((thumbs) => {
          setLoading(false);
          onSelectFile({
            name: filename || getFilenameFromUrl(inputUrl),
            url: inputUrl,
            alt,
            title,
            thumbs,
          });
        })
        .catch(() => {
          setLoading(false);
          onSelectFile({
            name: filename || getFilenameFromUrl(inputUrl),
            url: inputUrl,
            alt,
            title,
          });
        });
    } else {
      onSelectFile({
        name: filename || getFilenameFromUrl(inputUrl),
        url: inputUrl,
        alt,
        title,
      });
    }
  }, [onSelectFile, inputUrl, alt, title, filename]);

  const handleKeypressInputFileUrl = useCallback(
    (e) => {
      if (e.which === 13 && isValidUrl(inputUrl)) {
        handleClickInputFileUrl();
      }
    },
    [handleClickInputFileUrl, inputUrl]
  );

  const ValidateInputUrlButton = useCallback(() => {
    let buttonTitle = "";
    let disabled = true;
    if (inputUrl !== url) {
      if (isValidUrl(inputUrl)) {
        buttonTitle = "Valider cette URL";
        disabled = false;
      } else {
        buttonTitle = "URL invalide";
      }
    }
    return (
      <IconButton
        style={{ color: disabled ? "inherit" : "green" }}
        disabled={disabled}
        variant="outlined"
        title={buttonTitle}
        onClick={handleClickInputFileUrl}
      >
        <ValidateUrlIcon />
      </IconButton>
    );
  }, [handleClickInputFileUrl, inputUrl, url]);

  const Loader = () => (
    <Box display="flex" justifyContent="center" m={2}>
      <CircularProgress />
    </Box>
  );

  return (
    <Fragment>
      <input type="file" id={uploaderId} onChange={handleUploadFile} style={{ display: "none" }} />
      <div style={{ textAlign: "center" }}>{loading && <Loader />}</div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button fullWidth onClick={handleClickGed}>
            Depuis la bibliothèque
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth onClick={handleClickUpload}>
            Téléverser un fichier
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box pt={2}>
            <FormControl>
              <InputLabel shrink>
                Depuis une URL (Remplissez l&apos;adresse {image ? "de l'image" : "de la pièce jointe"} puis validez)
              </InputLabel>
              <Input
                style={{ width: "75%" }}
                value={inputUrl}
                onChange={handleChangeInputFileUrl}
                onKeyPress={handleKeypressInputFileUrl}
                startAdornment={
                  <InputAdornment position="start">
                    <ValidateInputUrlButton />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          {url && !loading && (
            <>
              {(image || imageExtensions?.includes(fileExtension)) && (
                <Box style={{ textAlign: "center" }}>
                  <Box my={2}>
                    <img
                      width={150}
                      src={(isValidUrl(url) && url) || "/default-image.jpg"}
                      onError={handleImageError}
                    />
                  </Box>
                  {(name || imageError) && (
                    <Box mb={2}>{imageError ? <span style={{ color: "red" }}>Image introuvable</span> : name}</Box>
                  )}
                </Box>
              )}
              {fileExtension && (
                <fieldset className={classes.fieldsetCustom}>
                  <legend>Référencement / accessibilité</legend>
                  {url && (
                    <Box>
                      <FormControl error={showError("name")}>
                        <InputLabel shrink>Nom {image ? "de l'image" : "du fichier"}</InputLabel>
                        <Input
                          value={filename}
                          onChange={handleNameChange}
                          endAdornment={<InputAdornment position="end">{fileExtension}</InputAdornment>}
                          style={{ width: "50%" }}
                        />
                        {showError("name") && <FormHelperText>Veuillez saisir un nom pour ce fichier</FormHelperText>}
                      </FormControl>
                    </Box>
                  )}
                  {image && (
                    <Grid container spacing={2} className={classes.image}>
                      <Grid item xs>
                        <InputLabel shrink>Titre de l&apos;image</InputLabel>
                        <Input value={title} onChange={handleTitleChange} />
                      </Grid>
                      <Grid item xs>
                        <InputLabel shrink>Texte alternatif</InputLabel>
                        <Input value={alt} onChange={handleAltChange} />
                      </Grid>
                    </Grid>
                  )}
                </fieldset>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="select-file"
        aria-describedby="select-file-from-library"
        open={gedModalOpen}
        onClose={() => setGedModalOpen(false)}
        size="xl"
      >
        <div>
          <div style={{ height: "75vh" }}>
            <NodesPicker
              tableProps={{
                rowHeight: 35,
              }}
              onSelectionChange={handleSelectNode}
            />
          </div>
          <ModalActions>
            <Button onClick={() => setGedModalOpen(false)} outlined>
              Annuler
            </Button>
            <Button onClick={handleClickValidateFile} disabled={!selectedFile || publishing}>
              Valider
            </Button>
          </ModalActions>
        </div>
      </Modal>
    </Fragment>
  );
};

FilePicker.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  file: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  pageVersion: PropTypes.shape(),
  image: PropTypes.bool,
  formSubmitted: PropTypes.bool,
};

FilePicker.defaultProps = {
  file: "",
  pageVersion: null,
  image: false,
  formSubmitted: false,
};

export default FilePicker;
